/* Mobile */
@media screen and (max-width: 700px) {
    .blog-container {
        padding: 2rem;
        padding-top: 4rem;
    }
}


/* Desktop */
@media screen and (min-width: 701px) {
    .blog-container {
        padding: 2rem 15rem;
    }

    .check-mark-wrapper {
        top: 3.1rem;
    }
}
