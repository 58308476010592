.psychedelic-background::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 20px; /* Adjust the height as needed */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), transparent);
}

.psychedelic-background::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30px; /* Adjust the height as needed */
    background: linear-gradient(to top, rgba(255, 255, 255, 0.75), transparent);
}